
















































import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import CompanyCmsProfileBaseWidget from '@/components/company-cms/CompanyCmsProfileBaseWidget.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import SlotTypeEnum from '@/utils/enums/SlotTypeEnum';
import CompanyCalendarInboxComponent
  from '@/components/company-meeting/CompanyCalendarInboxComponent.vue';
import { getUnixTime } from 'date-fns';
import { MeetingRequestsInboxView } from '@/utils/enums/MeetingRequestsInboxView';
import CompanyCalendarViewComponent
  from '@/components/company-meeting/CompanyCalendarViewComponent.vue';
import SegmentedControlsComponent from '@/components/SegmentedControlsComponent.vue';
import MeetingRequest from '@/models/graphql/MeetingRequest';
import NotificationEventType from '@/utils/enums/notification/NotificationEventType';
import VueBaseNotify from '@/utils/widgets/VueBaseNotify';
import SubscriptionEvent from '@/utils/types/SubscriptionEvent';
import Notification from '@/models/graphql/Notification';
import EntityType from '@/utils/enums/EntityType';
import MeetingParticipant from '@/models/graphql/MeetingParticipant';
import Meeting from '@/models/graphql/Meeting';

const companyCalendarStore = namespace('CompanyCalendarStore');
const salesPackageLimitStore = namespace('SalesPackageLimitStore');
const permissionManagerStore = namespace('PermissionManagerStore');

@Component({
  components: {
    SegmentedControlsComponent,
    CompanyCalendarViewComponent,
    CompanyCalendarInboxComponent,
    FontAwesomeComponent,
  },
})
/* eslint-disable @typescript-eslint/camelcase */
export default class CmsCompanyMeetingWidget extends mixins(CompanyCmsProfileBaseWidget, VueRegisterStoreWidget, VueBaseNotify) {
  @companyCalendarStore.State
  private meetingRequests!: MeetingRequest[];

  @companyCalendarStore.Action
  private loadMeetingRequests!: (filter: object) => Promise<void>;

  @companyCalendarStore.Action
  private loadMeetingRequestDetail!: (uid: string) => Promise<void>;

  @companyCalendarStore.Mutation
  private setSelectedMeetingRequest!: (meetingRequest: MeetingRequest | null) => void;

  @companyCalendarStore.Mutation
  private setIsDetailView!: (isDetail: boolean) => void;

  @companyCalendarStore.Mutation
  private setSelectedFilter!: (filter: MeetingRequestsInboxView) => void;

  @companyCalendarStore.Mutation
  private addMeetingRequest!: (m: MeetingRequest) => void;

  @companyCalendarStore.Mutation
  private addMeetingFromNotification!: (meeting: Meeting) => void;

  @companyCalendarStore.Mutation
  private updateMeetingParticipant!: (m: MeetingParticipant) => void;

  @companyCalendarStore.State
  private selectedFilter!: MeetingRequestsInboxView;

  @salesPackageLimitStore.Action
  private filterSlots!: (payload: { exhibitorUid: string; code: string })
    => Promise<{ type: string; limit: number; code: number }[]>;

  @permissionManagerStore.Getter
  private canHandleCompanyMeeting!: (companyUid: string) => boolean;

  private companyId!: string;

  private meetingRequestFilter = {};

  private meetingRequestSearch = {};

  private isMeetingFeatureEnabled = true;

  private isLoading = true;

  private selectedMeetingRequest: MeetingRequest | undefined | null = null;

  private widgetHeight = 'height: calc(100dvh - var(--main-header-height))';

  private get iHavePermission(): boolean {
    const { companyId } = this.$route.params;
    return this.canHandleCompanyMeeting(companyId)
      && this.isMeetingFeatureEnabled;
  }

  private get baseGqlFilter(): object {
    return {
      exhibitor: {
        uid: this.companyId,
      },
    };
  }

  created(): void {
    const { companyId } = this.$route.params;
    let filter = MeetingRequestsInboxView.INCOMING;
    this.setSelectedMeetingRequest(null);
    this.setIsDetailView(false);
    this.companyId = companyId;
    this.iHaveFeature(companyId)
      .finally(() => {
        if (!this.iHavePermission) {
          return;
        }
        this.componentId = `component-${this.widget.uid}`;
        this.notifyEvents = [NotificationEventType.MEETING_REQUEST, NotificationEventType.MEETING_PARTICIPANT];
        this.registerComponentForNotification();
        this.setDataConfig();
        if (this.$route.query?.detail) {
          this.loadMeetingRequestDetail(this.$route.query.detail as string)
            .finally(() => {
              this.isLoading = false;
            });
        }
        if (this.$route.query?.filter) {
          filter = this.$route.query.filter as MeetingRequestsInboxView;
        }
        this.onViewFilter(filter as MeetingRequestsInboxView, true)
          .finally(() => {
            this.isLoading = false;
          });
      });
  }

  notificationCallback(event: SubscriptionEvent): void {
    const notification = Notification.hydrate(event.data);
    if (notification.action === 'MEETING_REQUEST_RECEIVED'
      && notification.triggered
      && this.meetingRequests.filter((m) => notification.triggered?.uid === m.uid).length === 0
      && this.selectedFilter === MeetingRequestsInboxView.INCOMING
    ) {
      this.onViewFilter(MeetingRequestsInboxView.INCOMING);
    }
    if (notification.action === 'MEETING_REQUEST_ASSIGNED_MEETING'
      && notification.triggered) {
      const meetingRequest = MeetingRequest.hydrate(notification.triggered);
      if (meetingRequest && meetingRequest.meetingRequestMeeting) {
        this.addMeetingFromNotification(meetingRequest.meetingRequestMeeting);
      }
    }
    if (['MEETING_ACCEPTED', 'MEETING_DECLINED'].includes(notification.action as string)
      && notification
      && notification.triggered
      // eslint-disable-next-line no-underscore-dangle
      && notification.triggered.__typename === EntityType.MEETING_PARTICIPANT) {
      this.updateMeetingParticipant(notification.triggered as MeetingParticipant);
    }
  }

  private onSelectedMeetingRequest(mr: MeetingRequest): void {
    this.selectedMeetingRequest = mr;
  }

  private iHaveFeature(companyId: string): Promise<void> {
    if (this.featureByKey(FeatureKeys.COMMUNITY_ENABLE_COMPANY_MEETING)
      && this.featureByKey(FeatureKeys.COMMUNITY_ENABLE_COMPANY_MEETING)?.enabled) {
      if (this.featureByKey(FeatureKeys.COMMUNITY_NEW_LIMITS_CALCULATION_FEATURE)
        && this.featureByKey(FeatureKeys.COMMUNITY_NEW_LIMITS_CALCULATION_FEATURE)?.enabled) {
        return this.filterSlots({
          exhibitorUid: companyId,
          code: SlotTypeEnum.MEETING_FEATURE,
        })
          .then((response) => {
            if (response && response[0]) {
              this.isMeetingFeatureEnabled = response[0].limit === -1 || response[0].limit > 0;
            }
          });
      }
      this.isMeetingFeatureEnabled = true;
      return Promise.resolve();
    }
    this.isMeetingFeatureEnabled = false;
    return Promise.resolve();
  }

  private updated(): void {
    if (this.$el) {
      this.widgetHeight = `height: calc(100dvh - ${this.$el.getBoundingClientRect().top}px)`;
    }
  }

  private async onViewFilter(viewFilter: MeetingRequestsInboxView, routeReplace = false): Promise<void> {
    const url = this.$router.resolve({
      path: this.$route.path,
      params: this.$route.params,
      query: Object.assign(this.$route.query, { filter: viewFilter }),
    }).href;
    if (routeReplace) {
      window.history.replaceState(null, document.title, url);
    } else {
      window.history.pushState(null, document.title, url);
    }
    this.setSelectedFilter(viewFilter);
    if (viewFilter === MeetingRequestsInboxView.INCOMING) {
      this.meetingRequestFilter = {
        ...this.baseGqlFilter,
        trash_not: true,
        meeting: null,
      };
    } else if (viewFilter === MeetingRequestsInboxView.ARCHIVES) {
      this.meetingRequestFilter = {
        ...this.baseGqlFilter,
        trash: true,
      };
    } else if (viewFilter === MeetingRequestsInboxView.PENDING) {
      this.meetingRequestFilter = {
        ...this.baseGqlFilter,
        meeting: {
          participants_some: {
            state: 'INVITED',
          },
          startTimestamp_gt: getUnixTime(new Date()),
        },
      };
    } else if (viewFilter === MeetingRequestsInboxView.SCHEDULED) {
      this.meetingRequestFilter = {
        ...this.baseGqlFilter,
        _isFullyAcceptedFilter: '',
        meeting: {
          startTimestamp_gt: getUnixTime(new Date()),
        },
      };
    } else if (viewFilter === MeetingRequestsInboxView.DONE) {
      this.meetingRequestFilter = {
        ...this.baseGqlFilter,
        meeting: {
          endTimestamp_lt: getUnixTime(new Date()),
        },
      };
    }
    await this.loadMeetingRequests(
      {
        ...this.meetingRequestFilter,
        ...this.meetingRequestSearch,
      },
    );
    if (viewFilter === MeetingRequestsInboxView.INCOMING) {
      this.$eventsBus.emit('on-update-company-meeting-request-count');
    }
  }

  private onViewSearch(searchQuery: string): void {
    this.meetingRequestSearch = {};
    if (searchQuery.length > 0) {
      Object.assign(this.meetingRequestSearch, {
        user: {
          OR: [
            { name_contains: searchQuery },
            { employerName_contains: searchQuery },
          ],
        },
      });
    }
    this.loadMeetingRequests(
      {
        ...this.meetingRequestFilter,
        ...this.meetingRequestSearch,
      },
    );
  }
}
